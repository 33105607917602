import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import { TableComponentProps } from "./Component";
import { ComponentControls } from "./ComponentControls";
import ApplicationContext, { AppContext } from "./context/ApplicationContext";

type TableColumn = {
  header: string;
  textAlign: "left" | "center" | "right";
};

type TableCell = {
  value: string;
};

type TableRow = {
  cells: TableCell[];
};

function TableComponent(props: TableComponentProps) {
  const [highlightedColumn, setHighlightedColumn] = useState(-1);
  const [tableTitle, setTableTitle] = useState("");
  const [columns, setColumns] = useState<TableColumn[]>([
    { header: "", textAlign: "left" },
    { header: "", textAlign: "center" },
    { header: "", textAlign: "center" },
  ]);
  const [rows, setRows] = useState<TableRow[]>([
    { cells: [{ value: "" }, { value: "" }, { value: "" }] },
  ]);

  const [isSelected, setSelected] = useState<boolean>();
  const applicationContext = useContext(ApplicationContext) as AppContext;
  useEffect(() => {
    setSelected(applicationContext.selectedComponents.indexOf(props.id) >= 0);
  }, [applicationContext.selectedComponents]);
  return (
    <Rnd
      enableResizing={
        isSelected
          ? {
              top: true,
              bottom: true,
              left: true,
              right: true,
            }
          : {}
      }
      size={props.size}
      position={props.position}
      onResize={(e, direction, ref, delta, position) => {
        props.updateComponentData({
          position: { x: position.x, y: position.y },
          size: {
            width: parseInt(ref.style.width),
            height: parseInt(ref.style.height),
          },
        });
      }}
      onDragStop={(e, d) => {
        /*const possibleLeftValues = [props.position.x];
        let min_diff = Infinity;
        let x = 0;
        for (const leftValue of possibleLeftValues) {
          const diff = Math.abs(leftValue - d.x);
          if (diff < min_diff) {
            min_diff = diff;
            x = leftValue;
          }
        }*/
        props.updateComponentData({ position: { x: d.x, y: d.y } });
      }}
      className={`${isSelected ? "bring-to-front" : ""}`}
      style={{ padding: 4 }}
    >
      <div
        className={`table ${
          isSelected ? "selected-column selected-table" : ""
        }`}
        onClick={() => {
          props.selectComponent();
        }}
      >
        <ComponentControls
          deleteComponent={props.deleteComponent}
          bringToFront={props.bringToFront}
          sendToBack={props.sendToBack}
        />
        <input
          className="table-header"
          placeholder="Table Title"
          value={tableTitle}
          onChange={(e) => setTableTitle(e.target.value)}
        />
        <table>
          {isSelected && (
            <div
              className="add-column-button"
              onClick={() => {
                setRows((rows) => {
                  return rows.map((row) => {
                    return {
                      cells: [...row.cells, { value: "" } as TableCell],
                    };
                  });
                });
                setColumns((cols) => {
                  return [...cols, { header: "", textAlign: "center" }];
                });
              }}
            >
              <FontAwesomeIcon size="xs" icon={faPlus} />
            </div>
          )}
          {isSelected && (
            <div
              className="add-row-button"
              onClick={() => {
                setRows((currentRows) => {
                  const cells = columns.map((_) => {
                    return {
                      value: "",
                    } as TableCell;
                  });
                  return [...currentRows, { cells }];
                });
              }}
            >
              <FontAwesomeIcon size="xs" icon={faPlus} />
            </div>
          )}
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  onMouseEnter={() => setHighlightedColumn(index)}
                  onMouseLeave={() =>
                    setHighlightedColumn((oldIndex) => {
                      if (oldIndex === index) {
                        return -1;
                      } else {
                        return oldIndex;
                      }
                    })
                  }
                  className={
                    index === highlightedColumn && isSelected
                      ? `th-highlighted`
                      : ``
                  }
                >
                  <div
                    className="th-input"
                    placeholder="Column title"
                    contentEditable={true}
                    style={{
                      position: "relative",
                      textAlign: "left",
                    }}
                    onChange={(e) => {}}
                  >
                    {column.header}
                  </div>
                  {index === highlightedColumn && isSelected && (
                    <div
                      className={`th-button`}
                      onClick={() => {
                        setColumns((currentColumns) =>
                          currentColumns.filter((c, i) => {
                            return i !== index;
                          })
                        );
                      }}
                    >
                      <FontAwesomeIcon size="sm" icon={faTrash} />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {row.cells.map((cell, columnIndex) => {
                    if (columnIndex >= columns.length) {
                      return <></>;
                    }
                    return (
                      <td
                        key={`${rowIndex},${columnIndex}`}
                        style={{ textAlign: "left" }}
                        className={
                          columnIndex === highlightedColumn && isSelected
                            ? `td-highlighted`
                            : ``
                        }
                      >
                        <div
                          className="td-input"
                          placeholder="Value"
                          contentEditable={true}
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {cell.value}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Rnd>
  );
}

export default TableComponent;
