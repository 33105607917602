import {
  faColumns,
  faFont,
  faImage,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnComponentData, ComponentData } from "./Component";
import { v4 as uuidv4 } from "uuid";
import {
  COLUMN_SEP,
  PAGE_HEIGHT,
  PAGE_SIDE_MARGIN,
  PAGE_TOP_MARGIN,
  PAGE_WIDTH,
} from "./constants/page";
import { calculateWidth } from "./Column";
import { useEffect, useState } from "react";

export type ComponentGalleryProps = {
  defaultImageBackground: string;
  currentComponents: ComponentData[];
  addComponent(componentData: ComponentData): void;
};

type ColumnComponentDataWithName = ColumnComponentData & { name: string };

function ComponentGallery(props: ComponentGalleryProps) {
  const [templates, setTemplates] =
    useState<Partial<ColumnComponentDataWithName[]>>();
  const loadColumnTemplates = async () => {
    await fetch(`/api/templates/column`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setTemplates(data.templates));
  };

  function getPositionAndSizeForColumn() {
    const firstColumnComponents = props.currentComponents
      .filter((c) => c.type === "column")
      .filter((c) => c.position.x === PAGE_SIDE_MARGIN)
      .sort((a, b) => a.position.y - b.position.y);
    const MINIMUM_COLUMN_HEIGHT = 44;
    if (firstColumnComponents.length === 0) {
      return {
        position: { x: PAGE_SIDE_MARGIN, y: PAGE_TOP_MARGIN },
        size: {
          width: calculateWidth(2),
          height: PAGE_HEIGHT - 2 * PAGE_TOP_MARGIN,
        },
      };
    } else {
      const lastComponent =
        firstColumnComponents[firstColumnComponents.length - 1];
      if (
        PAGE_HEIGHT -
          (PAGE_TOP_MARGIN +
            lastComponent.position.y +
            lastComponent.size.height) >
        MINIMUM_COLUMN_HEIGHT
      ) {
        return {
          position: {
            x: PAGE_SIDE_MARGIN,
            y: lastComponent.position.y + lastComponent.size.height,
          },
          size: {
            width: calculateWidth(2),
            height:
              PAGE_HEIGHT -
              (PAGE_TOP_MARGIN +
                lastComponent.position.y +
                lastComponent.size.height),
          },
        };
      }
    }
    const secondColumnComponents = props.currentComponents
      .filter((c) => c.type === "column")
      .map((c) => c as ColumnComponentData)
      .filter(
        (c) =>
          c.position.x === (PAGE_WIDTH + COLUMN_SEP) / 2 ||
          c.numberOfColumns === 1
      )
      .sort((a, b) => a.position.y - b.position.y);
    if (secondColumnComponents.length === 0) {
      return {
        position: { x: (PAGE_WIDTH + COLUMN_SEP) / 2, y: PAGE_TOP_MARGIN },
        size: {
          width: calculateWidth(2),
          height: PAGE_HEIGHT - 2 * PAGE_TOP_MARGIN,
        },
      };
    } else {
      const lastComponent =
        secondColumnComponents[secondColumnComponents.length - 1];
      if (
        PAGE_HEIGHT -
          (PAGE_TOP_MARGIN +
            lastComponent.position.y +
            lastComponent.size.height) >
        MINIMUM_COLUMN_HEIGHT
      ) {
        return {
          position: {
            x: (PAGE_WIDTH + COLUMN_SEP) / 2,
            y: lastComponent.position.y + lastComponent.size.height,
          },
          size: {
            width: calculateWidth(2),
            height:
              PAGE_HEIGHT -
              (PAGE_TOP_MARGIN +
                lastComponent.position.y +
                lastComponent.size.height),
          },
        };
      }
    }
    return {
      position: {
        x: PAGE_SIDE_MARGIN,
        y: PAGE_TOP_MARGIN,
      },
      size: {
        width: calculateWidth(2),
        height: PAGE_HEIGHT - PAGE_TOP_MARGIN * 2,
      },
    };
  }

  useEffect(() => {
    loadColumnTemplates();
  }, []);
  return (
    <div className="left-controls">
      <div className="controls-label">add components</div>
      <div
        className="left-control-button-holder"
        onClick={() => {
          const { position, size } = getPositionAndSizeForColumn();
          const componentData: ComponentData = {
            type: "column",
            id: uuidv4(),
            numberOfColumns: 2,
            position,
            size,
            containers: [],
          };
          props.addComponent(componentData);
        }}
      >
        <div className="left-control-button">
          <FontAwesomeIcon icon={faColumns} size={"2x"} />
        </div>
        <div className="left-control-button-label">Add Text Column</div>
        {templates && (
          <div className="templates-holder">
            {templates.map(
              (template, index) =>
                template && (
                  <div
                    className="template-button"
                    key={index}
                    onClick={(e) => {
                      const { position, size } = getPositionAndSizeForColumn();
                      const componentData: ComponentData = {
                        ...template,
                        type: "column",
                        id: uuidv4(),
                        position,
                        numberOfColumns: 2,
                        size,
                        containers: [],
                      };
                      props.addComponent(componentData);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {template.name}
                  </div>
                )
            )}
          </div>
        )}
      </div>
      <div
        className="left-control-button-holder"
        onClick={() => {
          const componentData: ComponentData = {
            type: "image",
            id: uuidv4(),
            src: "",
            iteration: 30,
            growth: 0.025,
            position: { x: 0, y: 0 },
            size: { width: 800, height: 500 },
            offset: { x: 0, y: 0 },
            zoom: 1.0,
            layerColors: [props.defaultImageBackground],
            clipPoints: [],
            opacity: 1.0,
          };
          props.addComponent(componentData);
        }}
      >
        <div className="left-control-button">
          <FontAwesomeIcon icon={faImage} size={"2x"} />
        </div>
        <div className="left-control-button-label">
          Add
          <br />
          Image
        </div>
      </div>
      <div
        className="left-control-button-holder"
        onClick={() => {
          const componentData: ComponentData = {
            type: "table",
            id: uuidv4(),
            position: { x: 60, y: 40 },
            size: { width: 680, height: 400 },
          };
          props.addComponent(componentData);
        }}
      >
        <div className="left-control-button">
          <FontAwesomeIcon icon={faList} size={"2x"} />
        </div>
        <div className="left-control-button-label">
          Add
          <br />
          Table
        </div>
      </div>
      <div
        className="left-control-button-holder"
        onClick={() => {
          const componentData: ComponentData = {
            type: "floating_text",
            id: uuidv4(),
            position: { x: 45, y: 45 },
            size: { width: 200, height: 34 },
            color: "white",
            font: "Zatanna",
          };
          props.addComponent(componentData);
        }}
      >
        <div className="left-control-button">
          <FontAwesomeIcon icon={faFont} size={"2x"} />
        </div>
        <div className="left-control-button-label">
          Add
          <br />
          Floating Text
        </div>
      </div>
    </div>
  );
}
export default ComponentGallery;
