import { marked } from "marked";
import { useEffect, useRef, useState } from "react";

type ChatMessage = {
  message: string;
  role: "user" | "assistant";
};

function ChatMessageItem(props: { chatMessage: ChatMessage }) {
  return (
    <div
      className={`chat-message chat-message-${props.chatMessage.role}`}
      contentEditable={false}
      dangerouslySetInnerHTML={{ __html: marked(props.chatMessage.message) }}
    ></div>
  );
}

function ChatInput(props: {
  sendMessage: (message: string) => Promise<void>;
  dismiss: () => void;
  loading: boolean;
}) {
  const textAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!props.loading && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [props.loading]);
  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key === "Enter" &&
      textAreaRef.current &&
      textAreaRef.current.textContent
    ) {
      await props.sendMessage(textAreaRef.current.textContent);
    }
    if (e.key === "Escape") {
      props.dismiss();
    }
  };
  return (
    <div style={{ display: "table" }}>
      <div
        ref={textAreaRef}
        className="chat-input"
        style={{ display: "table-cell", verticalAlign: "middle" }}
        placeholder="Send a message"
        onKeyDown={handleKeyPress}
        contentEditable={true}
      />
    </div>
  );
}

export default function ChatComponent(props: {
  id: string;
  dismiss: () => void;
}) {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const sendMessage = async (message: string) => {
    setLoading(true);
    setChatHistory((chatHistory) => [
      ...chatHistory,
      { message, role: "user" },
    ]);
    await fetch(`/api/text/${props.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: message,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setChatHistory((chatHistory) => [
          ...chatHistory,
          { message: data.response as string, role: "assistant" },
        ]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadChatHistory = async () => {
    setLoading(true);
    await fetch(`/api/text/${props.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setChatHistory(data.chatHistory);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const clearChatHistory = async () => {
    setLoading(true);
    await fetch(`/api/text/${props.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        setChatHistory([]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadChatHistory();
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }, [chatHistory]);

  return (
    <div className="chat-component">
      <div className="chat-component-inner">
        <div className="chat-component-items">
          {chatHistory.map((chatMessage, index) => (
            <ChatMessageItem chatMessage={chatMessage} key={index} />
          ))}
          {loading && (
            <ChatMessageItem
              chatMessage={{ message: "...", role: "assistant" }}
            />
          )}
          <div ref={bottomRef} />
        </div>
        {!loading && (
          <ChatInput
            sendMessage={sendMessage}
            dismiss={props.dismiss}
            loading={loading}
          />
        )}
        {!loading && (
          <div className="chat-component-controls">
            <div
              className="component-properties-button component-properties-button-danger"
              onClick={clearChatHistory}
            >
              Clear Chat
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
