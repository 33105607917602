import {
  faArrowDown,
  faArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ComponentControlsProps = {
  deleteComponent: () => void;
  bringToFront: () => void;
  sendToBack: () => void;
};

export function ComponentControls(props: ComponentControlsProps) {
  return (
    <div className="component-buttons-holder">
      <div
        className={`component-button`}
        onClick={(e) => {
          e.stopPropagation();
          props.bringToFront();
        }}
        title={"Bring to front"}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </div>
      <div
        className={`component-button`}
        onClick={(e) => {
          e.stopPropagation();
          props.sendToBack();
        }}
        title={"Send to back"}
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </div>
      <div
        className={`component-button`}
        onClick={(e) => {
          e.stopPropagation();
          props.deleteComponent();
        }}
        title={"Delete"}
      >
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </div>
  );
}
