import ColorPicker from "./ColorPicker";

export type MultipleSelectedComponentPropertiesProps = {
  deleteComponents: () => void;
  sendComponentsToBack: () => void;
  bringComponentsToFront: () => void;
};

function MultipleSelectedComponentProperties(
  props: MultipleSelectedComponentPropertiesProps
) {
  return (
    <div className="image-properties">
      <div className="sections-holder">
        <div className="sections-title">Multiple Components</div>
      </div>
      <div className="section section-button-holder">
        <div
          className="component-properties-button"
          onClick={props.bringComponentsToFront}
          style={{ width: 120, textAlign: "center" }}
        >
          Bring to front
        </div>
      </div>
      <div className="section section-button-holder">
        <div
          className="component-properties-button"
          onClick={props.sendComponentsToBack}
          style={{ width: 120, textAlign: "center" }}
        >
          Send to back
        </div>
      </div>
      <div className="section section-button-holder">
        <div
          className="component-properties-button component-properties-button-danger"
          onClick={props.deleteComponents}
          style={{ width: 120, textAlign: "center" }}
        >
          Delete Components
        </div>
      </div>
    </div>
  );
}

export default MultipleSelectedComponentProperties;
