import {
  faCut,
  faArrowsUpDownLeftRight,
  faFileImage,
  faImages,
  faSpinner,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { calculateMaskColor } from "./color";
import { ImageComponentData, ImageComponentProps, Point } from "./Component";
import imglyRemoveBackground from "@imgly/background-removal";
import { ComponentControls } from "./ComponentControls";
import ApplicationContext, { AppContext } from "./context/ApplicationContext";

type ImageHolderOperation = "move" | "cut" | "edit";

type ClipPathPointsProps = {
  width: number;
  height: number;
  editing: boolean;
  clipPoints: Point[];
  updateClipPoints: (newClipPoints: Point[]) => void;
};

function isPointInBorder(
  point: Point,
  height: number,
  width: number,
  threshold: number
): boolean {
  return (
    point.x <= threshold ||
    width - point.x <= threshold ||
    point.y <= threshold ||
    height - point.y <= threshold
  );
}

function ClipPathPoints(props: ClipPathPointsProps) {
  const THRESHOLD = 4;
  const [clipPathInProgress, setClipPathInProgress] = React.useState<Point[]>(
    []
  );
  const [hoverPoint, setHoverPoint] = React.useState<Point>();
  if (!props.editing) {
    return <div />;
  }

  const hoverPointClass: string = `clip-path-point clip-path-point-hover ${
    hoverPoint &&
    isPointInBorder(hoverPoint, props.height, props.width, THRESHOLD)
      ? `clip-path-point-on-border`
      : ``
  }`;
  if (clipPathInProgress.length > 0) {
    return (
      <div
        className="clip-path-points"
        onMouseMove={(e) => {
          const boundingRect = (
            e.target as HTMLDivElement
          ).getBoundingClientRect();
          const point = {
            x: e.clientX - boundingRect.left,
            y: e.clientY - boundingRect.top,
          };
          setHoverPoint(point);
        }}
        onMouseLeave={() => setHoverPoint(undefined)}
        onClick={(e) => {
          const boundingRect = (
            e.target as HTMLDivElement
          ).getBoundingClientRect();
          const point = {
            x: e.clientX - boundingRect.left,
            y: e.clientY - boundingRect.top,
          };
          if (Math.abs(point.x - props.width) < THRESHOLD) {
            point.x = props.width;
          }
          if (Math.abs(point.x) < THRESHOLD) {
            point.x = 0;
          }
          if (Math.abs(point.y - props.height) < THRESHOLD) {
            point.y = props.height;
          }
          if (Math.abs(point.y) < THRESHOLD) {
            point.y = 0;
          }
          const newClipPathInProgress = [...clipPathInProgress, point];
          setClipPathInProgress(newClipPathInProgress);
        }}
      >
        {clipPathInProgress.map((point, index) => {
          if (index === 0) {
            return (
              <div
                key={index}
                onClick={(e) => {
                  // close
                  const newClipPathInProgress = [...clipPathInProgress, point];
                  props.updateClipPoints(newClipPathInProgress);
                  setClipPathInProgress([]);
                  e.stopPropagation();
                }}
                className="clip-path-point clip-path-point-in-progress"
                style={{ left: point.x - 6, top: point.y - 6 }}
              />
            );
          }
          return (
            <div
              key={index}
              className="clip-path-point clip-path-point-in-progress"
              style={{ left: point.x - 6, top: point.y - 6 }}
            />
          );
        })}
        {hoverPoint && (
          <div
            className={hoverPointClass}
            style={{ left: hoverPoint.x - 6, top: hoverPoint.y - 6 }}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className="clip-path-points"
        onMouseMove={(e) => {
          const boundingRect = (
            e.target as HTMLDivElement
          ).getBoundingClientRect();
          const point = {
            x: e.clientX - boundingRect.left,
            y: e.clientY - boundingRect.top,
          };
          setHoverPoint(point);
        }}
        onMouseLeave={() => setHoverPoint(undefined)}
        onClick={(e) => {
          const boundingRect = (
            e.target as HTMLDivElement
          ).getBoundingClientRect();
          const point = {
            x: e.clientX - boundingRect.left,
            y: e.clientY - boundingRect.top,
          };
          if (Math.abs(point.x - props.width) < THRESHOLD) {
            point.x = props.width;
          }
          if (Math.abs(point.x) < THRESHOLD) {
            point.x = 0;
          }
          if (Math.abs(point.y - props.height) < THRESHOLD) {
            point.y = props.height;
          }
          if (Math.abs(point.y) < THRESHOLD) {
            point.y = 0;
          }
          const newClipPathInProgress = [...clipPathInProgress, point];
          setClipPathInProgress(newClipPathInProgress);
          e.stopPropagation();
        }}
      >
        {props.clipPoints.map((point, index) => (
          <div
            key={index}
            className="clip-path-point"
            style={{ left: point.x - 6, top: point.y - 6 }}
          />
        ))}
        {hoverPoint && (
          <div
            className={hoverPointClass}
            style={{ left: hoverPoint.x - 6, top: hoverPoint.y - 6 }}
          />
        )}
      </div>
    );
  }
}

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function generateDyckPath(
  start: Point,
  end: Point,
  topLeft: Point,
  bottomRight: Point,
  horizontalStep: number,
  verticalStep: number
) {
  const clipPath: Point[] = [start];
  const xdiff = Math.abs(start.x - end.x);
  const ydiff = Math.abs(start.y - end.y);
  const minY: number = topLeft.y;
  const minX: number = topLeft.x;
  const maxY: number = bottomRight.y;
  const maxX: number = bottomRight.x;
  let currentPoint: Point = { x: start.x, y: start.y };
  if (xdiff > ydiff) {
    // we will move horizontally at every step, no negative horizontal movement
    let up = 0;
    let down = 0;
    const stepValue = end.x > start.x ? horizontalStep : -horizontalStep;
    const totalMoves = Math.abs(end.x - start.x) / horizontalStep;
    const upLimit = totalMoves / 2 + (start.y - end.y) / verticalStep / 2;
    const downLimit = totalMoves / 2 - (start.y - end.y) / verticalStep / 2;
    for (let i = 0; i < totalMoves; i++) {
      currentPoint = { x: currentPoint.x + stepValue, y: currentPoint.y };
      if (
        up < upLimit &&
        down < downLimit &&
        currentPoint.y < maxY &&
        currentPoint.y > minY
      ) {
        // can go either way
        const upProbability =
          (upLimit - up) / (upLimit - up + (downLimit - down));
        if (Math.random() < upProbability) {
          currentPoint.y -= verticalStep;
          up += 1;
        } else {
          currentPoint.y += verticalStep;
          down += 1;
        }
      } else if (up < upLimit && currentPoint.y > minY) {
        currentPoint.y -= verticalStep;
        up += 1;
      } else if (down < downLimit && currentPoint.y < maxY) {
        currentPoint.y += verticalStep;
        down += 1;
      }
      clipPath.push(currentPoint);
    }
    clipPath.push(end);
  } else {
    // we will move vertically at every step, no negative vertical movement
    let left = 0;
    let right = 0;
    const stepValue = end.y > start.y ? verticalStep : -verticalStep;
    const totalMoves = Math.abs(end.y - start.y) / verticalStep;
    const leftLimit = totalMoves / 2 + (start.x - end.x) / horizontalStep / 2;
    const rightLimit = totalMoves / 2 - (start.x - end.x) / horizontalStep / 2;
    for (let i = 0; i < totalMoves; i++) {
      currentPoint = { x: currentPoint.x, y: currentPoint.y + stepValue };
      if (
        left < leftLimit &&
        right < rightLimit &&
        currentPoint.x < maxX &&
        currentPoint.x > minX
      ) {
        // can go either way
        const leftProbability =
          (leftLimit - left) / (leftLimit - left + (rightLimit - right));
        if (Math.random() < leftProbability) {
          currentPoint.x -= horizontalStep;
          left += 1;
        } else {
          currentPoint.x += horizontalStep;
          right += 1;
        }
      } else if (left < leftLimit && currentPoint.x > minX) {
        currentPoint.x -= horizontalStep;
        left += 1;
      } else if (right < rightLimit && currentPoint.x < maxX) {
        currentPoint.x += horizontalStep;
        right += 1;
      }
      clipPath.push(currentPoint);
    }
    clipPath.push(end);
  }
  return clipPath;
}

type ImageGenerationInputProps = {
  id: string;
  width: number;
  height: number;
  src: string;
  setLoading: React.Dispatch<React.SetStateAction<Boolean | undefined>>;
  setDone: (reloadImage: boolean) => void;
  updateComponentData: (newData: Partial<ImageComponentData>) => void;
  preselect?: boolean;
};

function ImageGenerationInput(props: ImageGenerationInputProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    resizeTextArea();
  };
  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set it to the scroll height
    }
  };
  const handleKeyPress = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") {
      props.setLoading(true);
      if (textAreaRef.current) {
        textAreaRef.current.value = " ";
        textAreaRef.current.blur();
      }
      await fetch(`/api/img/${props.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: inputValue,
          width: props.width,
          height: props.height,
          image: props.src ? true : false,
        }),
      });
      props.setLoading(false);
      props.setDone(props.src ? true : false);
      props.updateComponentData({
        src: `/ugc/${props.id}`,
      });
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (props.preselect && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [props.preselect]);
  return (
    <textarea
      ref={textAreaRef}
      className="image-generation-input"
      placeholder={props.src ? "Describe your edit" : "Describe your image"}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      style={{ resize: "none" }}
    />
  );
}

export function ImageHolder(props: ImageComponentProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [dragStart, setDragStart] = React.useState<Point | undefined>();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [operationState, setOperationState] = React.useState<
    ImageHolderOperation | undefined
  >();
  const [image, setImage] = React.useState<HTMLImageElement>();
  const [clipPath, setClipPath] = React.useState<Point[][]>([]);
  const [cssFilter, setCssFilter] = React.useState<string>();
  const [removingBackground, setRemovingBackground] = React.useState<Boolean>();

  const [isSelected, setSelected] = useState<boolean>();
  const applicationContext = useContext(ApplicationContext) as AppContext;
  useEffect(() => {
    setSelected(applicationContext.selectedComponents.indexOf(props.id) >= 0);
  }, [applicationContext.selectedComponents]);

  // Load the image once the source changes
  React.useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setImage(img);
    };
    img.src = props.src;
  }, [props.src]);

  React.useEffect(() => {
    let newClipPaths: Point[][] = [];
    for (let iteration = 0; iteration < props.iteration; iteration++) {
      let newClipPath: Point[] = [];
      const maxx = Math.max(...props.clipPoints.map((p) => p.x));
      const minx = Math.min(...props.clipPoints.map((p) => p.x));
      const maxy = Math.max(...props.clipPoints.map((p) => p.y));
      const miny = Math.min(...props.clipPoints.map((p) => p.y));
      const w = maxx - minx;
      const h = maxy - miny;
      for (let i = 0; i < props.clipPoints.length - 1; i++) {
        const [p1, p2] = [props.clipPoints[i], props.clipPoints[i + 1]];
        if (
          (p1.x === 0 && p2.x === 0) ||
          (p1.x === props.size.width && p2.x === props.size.width) ||
          (p1.y === 0 && p2.y === 0) ||
          (p1.y === props.size.height && p2.y === props.size.height)
        ) {
          newClipPath = [
            ...newClipPath,
            props.clipPoints[i],
            props.clipPoints[i + 1],
          ];
        } else {
          const growth = props.growth;
          newClipPath = [
            ...newClipPath,
            ...generateDyckPath(
              {
                x:
                  props.clipPoints[i].x * (1.0 + iteration * growth) -
                  minx * (iteration * growth) -
                  (w * (iteration * growth)) / 2,
                y:
                  props.clipPoints[i].y * (1.0 + iteration * growth) -
                  miny * (iteration * growth) -
                  (h * (iteration * growth)) / 2,
              },
              {
                x:
                  props.clipPoints[i + 1].x * (1.0 + iteration * growth) -
                  minx * (iteration * growth) -
                  (w * (iteration * growth)) / 2,
                y:
                  props.clipPoints[i + 1].y * (1.0 + iteration * growth) -
                  miny * (iteration * growth) -
                  (h * (iteration * growth)) / 2,
              },
              { x: 0, y: 0 },
              { x: props.size.width, y: props.size.height },
              0.15 + 0.2 * iteration,
              0.15 + 0.2 * iteration
            ),
          ];
        }
      }
      newClipPaths.push(newClipPath);
    }
    setClipPath(newClipPaths);
    setOperationState(undefined);
  }, [props.clipPoints, props.iteration, props.growth, props.layerColors]);

  // Draw the image on the canvas
  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx && image) {
        const dpr = window.devicePixelRatio;
        const rect = { width: props.size.width, height: props.size.height };
        canvas.width = rect.width * dpr;
        canvas.height = rect.height * dpr;
        ctx.scale(dpr, dpr);
        canvas.style.width = `${rect.width}px`;
        canvas.style.height = `${rect.height}px`;

        if (clipPath.length > 0 && clipPath[0].length > 0) {
          const firstPath = clipPath[0];
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(firstPath[0].x, firstPath[1].y);
          for (let i = 1; i < firstPath.length; i++) {
            ctx.lineTo(firstPath[i].x, firstPath[i].y);
          }
          ctx.closePath();
          ctx.clip();
        }
        ctx.globalAlpha = 1;
        ctx.drawImage(
          image,
          props.offset.x,
          props.offset.y,
          rect.width * props.zoom,
          image.height * (rect.width / image.width) * props.zoom
        );
        for (let j = 1; j < clipPath.length; j++) {
          if (clipPath[j].length === 0) {
            continue;
          }
          ctx.restore();
          ctx.globalCompositeOperation = "destination-over";
          ctx.globalAlpha = 0.3 / j;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(clipPath[j][0].x, clipPath[j][1].y);
          for (let i = 1; i < clipPath[j].length; i++) {
            ctx.lineTo(clipPath[j][i].x, clipPath[j][i].y);
          }
          ctx.fillStyle = "transparent";
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(
            image,
            props.offset.x,
            props.offset.y,
            rect.width * props.zoom,
            image.height * (rect.width / image.width) * props.zoom
          );
          ctx.beginPath();
          ctx.globalCompositeOperation = "destination-over";
          ctx.rect(0, 0, props.size.width, props.size.height);
          ctx.closePath();
          ctx.fillStyle = props.layerColors[j % props.layerColors.length];
          ctx.fill();
        }
      }
    }
  }, [image, props.offset, props.zoom, props.size, clipPath]);

  React.useEffect(() => {
    let maskFilter: string | undefined;
    if (props.colorMask) {
      maskFilter = calculateMaskColor(props.colorMask);
    }
    setCssFilter(maskFilter);
  }, [props.colorMask]);

  return (
    <Rnd
      disableDragging={!(operationState === undefined && isSelected)}
      enableResizing={operationState === undefined && isSelected}
      size={props.size}
      position={{ x: props.position.x, y: props.position.y }}
      onDrag={(e, d) => {
        e.stopPropagation();
        if (isSelected && applicationContext.selectedComponents.length > 1) {
          // there are others selected. try to move them as well
          applicationContext.setGroupDrag(d);
        } else {
          props.updateComponentData({
            position: { x: d.x, y: d.y },
          });
        }
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        e.stopPropagation();
      }}
      onResize={(e, direction, ref, delta, position) => {
        e.stopPropagation();
        props.updateComponentData({
          position,
          size: {
            width: parseInt(ref.style.width),
            height: parseInt(ref.style.height),
          },
        });
      }}
    >
      <div
        className={`component image-controller ${
          operationState ? `image-controller-${operationState.toString()}` : ``
        } ${isSelected ? `image-controller-selected bring-to-front` : ``}`}
        ref={containerRef}
        style={{
          width: props.size.width,
          height: props.size.height,
        }}
        onMouseDown={(e) => {
          if (operationState === "move") {
            setDragStart({
              x: -props.offset.x + e.pageX - e.currentTarget.offsetLeft + 1,
              y: -props.offset.y + e.pageY - e.currentTarget.offsetTop + 1,
            });
          }
        }}
        onMouseMove={(e) => {
          if (operationState === "move" && dragStart) {
            props.updateComponentData({
              offset: {
                x: -dragStart.x + (e.pageX - e.currentTarget.offsetLeft + 1),
                y: -dragStart.y + (e.pageY - e.currentTarget.offsetTop + 1),
              },
            });
          }
        }}
        onMouseUp={() => {
          if (operationState === "move") {
            setDragStart(undefined);
          }
        }}
        onClick={(e) => {
          if (operationState === undefined) {
            props.selectComponent();
          }
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case "ArrowDown":
              props.updateComponentData({
                position: {
                  x: props.position.x,
                  y: props.position.y + 1,
                },
              });
              break;
            case "ArrowUp":
              props.updateComponentData({
                position: {
                  x: props.position.x,
                  y: props.position.y - 1,
                },
              });
              break;
            case "ArrowLeft":
              props.updateComponentData({
                position: {
                  x: props.position.x - 1,
                  y: props.position.y,
                },
              });
              break;
            case "ArrowRight":
              props.updateComponentData({
                position: {
                  x: props.position.x + 1,
                  y: props.position.y,
                },
              });
              break;
            default:
              break;
          }
        }}
      >
        {removingBackground && (
          <div className="image-controller-removing-background">
            <FontAwesomeIcon
              className="image-controller-removing-background-indicator"
              size="lg"
              icon={faSpinner}
              spin
            />
          </div>
        )}
        {operationState === "cut" && (
          <div className="image-controller-cut-frame image-controller-cut-frame-top" />
        )}
        {operationState === "cut" && (
          <div className="image-controller-cut-frame image-controller-cut-frame-bottom" />
        )}
        {operationState === "cut" && (
          <div className="image-controller-cut-frame image-controller-cut-frame-left" />
        )}
        {operationState === "cut" && (
          <div className="image-controller-cut-frame image-controller-cut-frame-right" />
        )}
        <ComponentControls
          deleteComponent={props.deleteComponent}
          bringToFront={props.bringToFront}
          sendToBack={props.sendToBack}
        />
        {
          <div className="image-controls">
            <div
              className={`image-button`}
              onClick={() => {
                imageInputRef.current?.click();
              }}
            >
              <input
                type="file"
                accept="image/png, image/jpeg"
                hidden
                ref={imageInputRef}
                onChange={async (e) => {
                  if (e.target.files && e.target.files[0]) {
                    const data = new FormData();
                    data.append("file", e.target.files[0], props.id);
                    await fetch("/api/upload", {
                      method: "POST",
                      body: data,
                    });
                    props.updateComponentData({
                      src: `/ugc/${props.id}`,
                    });
                  }
                }}
              />
              <FontAwesomeIcon icon={faFileImage} title={"Upload image yo"} />
            </div>
            <div
              className={`image-button ${
                operationState === "cut" ? `image-button-selected` : ``
              }`}
              onClick={(e) => {
                if (operationState === "cut") {
                  setOperationState(undefined);
                } else {
                  setOperationState("cut");
                }
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon={faCut} title={"Set borders"} />
            </div>
            <div
              className={`image-button`}
              onClick={async (e) => {
                e.stopPropagation();
                if (!image) {
                  return;
                }
                setRemovingBackground(true);
                await imglyRemoveBackground(image?.src)
                  .then(async (blob: Blob) => {
                    const data = new FormData();
                    data.append("file", blob, props.id);
                    await fetch("/api/upload", {
                      method: "POST",
                      body: data,
                    });
                    const img = new Image();
                    img.onload = function () {
                      setImage(img);
                    };
                    img.src = props.src;
                    setRemovingBackground(false);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              <FontAwesomeIcon icon={faImages} title={"Remove background"} />
            </div>
            <div
              className={`image-button ${
                operationState === "move" ? `image-button-selected` : ``
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (operationState === "move") {
                  setOperationState(undefined);
                } else {
                  setOperationState("move");
                }
              }}
            >
              <FontAwesomeIcon
                icon={faArrowsUpDownLeftRight}
                title={"Move image inside block"}
              />
            </div>
            {props.src && (
              <div
                className={`image-button ${
                  operationState === "edit" ? `image-button-selected` : ``
                }`}
                onClick={(e) => {
                  if (operationState === "edit") {
                    setOperationState(undefined);
                  } else {
                    setOperationState("edit");
                  }
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  title={"Edit image description to regenerate"}
                />
              </div>
            )}
          </div>
        }
        {isSelected && (!props.src || operationState === "edit") && (
          <div className="image-generation">
            <ImageGenerationInput
              updateComponentData={props.updateComponentData}
              setLoading={setRemovingBackground}
              id={props.id}
              width={props.size.width}
              height={props.size.height}
              src={props.src}
              preselect={true}
              setDone={(reloadImage) => {
                if (reloadImage) {
                  setOperationState(undefined);
                  const img = new Image();
                  img.onload = function () {
                    setImage(img);
                  };
                  img.src = `${props.src}?v=${Math.random()}`;
                }
              }}
            />
          </div>
        )}
        <canvas
          className={`folio-image ${props.src ? "" : "folio-empty-image"}`}
          ref={canvasRef}
          style={{
            opacity: props.opacity,
            filter: cssFilter,
          }}
          width={props.size.width}
          height={props.size.height}
        />
        <ClipPathPoints
          width={props.size.width}
          height={props.size.height}
          editing={operationState === "cut"}
          clipPoints={props.clipPoints}
          updateClipPoints={(newClipPoints) => {
            props.updateComponentData({ clipPoints: newClipPoints });
          }}
        />
      </div>
    </Rnd>
  );
}

export default ImageHolder;
