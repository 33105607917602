import ColumnProperties from "./ColumnProperties";
import { ComponentData, PageData } from "./Component";
import FloatingTextProperties from "./FloatingTextProperties";
import ImageProperties from "./ImageProperties";
import MultipleSelectedComponentProperties from "./MultipleSelectedComponentProperties";
import PageProperties from "./PageProperties";

export type ComponentPropertiesProps = {
  pageData: Omit<PageData, "id" | "components">;
  index: number;
  updatePageData: (newPageData: Partial<PageData>) => void;
  deletePage: () => void;
  selectedComponent?: ComponentData;
  allSelectedComponents?: ComponentData[];
  deleteComponents: () => void;
  sendComponentsToBack: () => void;
  bringComponentsToFront: () => void;
  updateComponentData: (
    componentId: string,
    newData: Partial<ComponentData>
  ) => void;
};

function ComponentProperties(props: ComponentPropertiesProps) {
  const selectedComponent = props.selectedComponent;
  return (
    <div
      className={`right-controls ${
        props.selectedComponent !== undefined ||
        (props.allSelectedComponents && props.allSelectedComponents?.length > 0)
          ? `right-controls-toggled`
          : ``
      }`}
    >
      <div className="controls-label">properties</div>
      {selectedComponent && selectedComponent.type === "image" && (
        <ImageProperties
          position={selectedComponent.position}
          size={selectedComponent.size}
          offset={selectedComponent.offset}
          zoom={selectedComponent.zoom}
          growth={selectedComponent.growth}
          iteration={selectedComponent.iteration}
          layerColors={selectedComponent.layerColors}
          opacity={selectedComponent.opacity}
          colorMask={selectedComponent.colorMask}
          updateComponentData={(newData) =>
            props.updateComponentData(selectedComponent.id, newData)
          }
        />
      )}
      {selectedComponent && selectedComponent.type === "column" && (
        <ColumnProperties
          updateComponentData={(newData) =>
            props.updateComponentData(selectedComponent.id, newData)
          }
          numberOfColumns={selectedComponent.numberOfColumns}
          position={selectedComponent.position}
          size={selectedComponent.size}
          backgroundColor={selectedComponent.backgroundColor}
          shadowColor={selectedComponent.shadowColor}
          shadowSize={selectedComponent.shadowSize}
          borderTop={selectedComponent.borderTop}
          borderLeft={selectedComponent.borderLeft}
          borderBottom={selectedComponent.borderBottom}
          borderRight={selectedComponent.borderRight}
          paddingTop={selectedComponent.paddingTop}
          paddingRight={selectedComponent.paddingRight}
          paddingBottom={selectedComponent.paddingBottom}
          paddingLeft={selectedComponent.paddingLeft}
          backgroundImage={selectedComponent.backgroundImage}
        />
      )}
      {selectedComponent && selectedComponent.type === "floating_text" && (
        <FloatingTextProperties
          size={selectedComponent.size}
          position={selectedComponent.position}
          color={selectedComponent.color}
          updateComponentData={(newData) =>
            props.updateComponentData(selectedComponent.id, newData)
          }
        />
      )}
      {selectedComponent === undefined &&
        (props.allSelectedComponents === undefined ||
          props.allSelectedComponents.length === 0) && (
          <PageProperties
            index={props.index}
            pageData={props.pageData}
            updatePageData={props.updatePageData}
            deletePage={props.deletePage}
          />
        )}
      {selectedComponent === undefined &&
        props.allSelectedComponents &&
        props.allSelectedComponents.length > 0 && (
          <MultipleSelectedComponentProperties
            deleteComponents={props.deleteComponents}
            sendComponentsToBack={props.sendComponentsToBack}
            bringComponentsToFront={props.bringComponentsToFront}
          />
        )}
    </div>
  );
}
export default ComponentProperties;
