import { useContext, useEffect, useRef, useState } from "react";
import {
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  Editor,
  EditorState,
  RichUtils,
} from "draft-js";
import "./Editor.css";
import { FloatingTextComponentProps } from "./Component";
import { Rnd } from "react-rnd";
import { ComponentControls } from "./ComponentControls";
import ApplicationContext, { AppContext } from "./context/ApplicationContext";

function FloatingText(props: FloatingTextComponentProps) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const editor = useRef<Editor>(null);
  function focusEditor() {
    editor.current?.focus();
  }

  useEffect(() => {
    if (props.contentState) {
      const contentState = convertFromRaw(props.contentState);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, []);

  function handleKeyCommand(
    command: string,
    editorState: EditorState
  ): DraftHandleValue {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const newRawContentState = convertToRaw(contentState);
    props.updateComponentData({ contentState: newRawContentState });
  }, [editorState]);

  const [isSelected, setSelected] = useState<boolean>();
  const applicationContext = useContext(ApplicationContext) as AppContext;
  useEffect(() => {
    setSelected(applicationContext.selectedComponents.indexOf(props.id) >= 0);
  }, [applicationContext.selectedComponents]);

  return (
    <Rnd
      enableResizing={isSelected}
      size={props.size}
      position={props.position}
      onResize={(e, direction, ref, delta, position) => {
        props.updateComponentData({
          position,
          size: {
            width: parseInt(ref.style.width),
            height: parseInt(ref.style.height),
          },
        });
      }}
      onDrag={(e, d) => {
        if (isSelected && applicationContext.selectedComponents.length > 1) {
          // there are others selected. try to move them as well
          applicationContext.setGroupDrag(d);
        } else {
          props.updateComponentData({
            position: { x: d.x, y: d.y },
          });
        }
      }}
      className={`${isSelected ? "bring-to-front" : ""}`}
    >
      <div
        className={`component floating-text ${
          isSelected ? "selected-floating-text" : ""
        }`}
        onClick={(e) => {
          focusEditor();
          props.selectComponent();
        }}
        style={{
          color: `${props.color}`,
          caretColor: `${props.color}`,
          textShadow: `0px 0px 1px black`,
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case "ArrowDown":
              props.updateComponentData({
                position: {
                  x: props.position.x,
                  y: props.position.y + 1,
                },
              });
              break;
            case "ArrowUp":
              props.updateComponentData({
                position: {
                  x: props.position.x,
                  y: props.position.y - 1,
                },
              });
              break;
            case "ArrowLeft":
              props.updateComponentData({
                position: {
                  x: props.position.x - 1,
                  y: props.position.y,
                },
              });
              break;
            case "ArrowRight":
              props.updateComponentData({
                position: {
                  x: props.position.x + 1,
                  y: props.position.y,
                },
              });
              break;
            default:
              break;
          }
        }}
      >
        <ComponentControls
          deleteComponent={props.deleteComponent}
          bringToFront={props.bringToFront}
          sendToBack={props.sendToBack}
        />
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={(state) => {
            setEditorState(state);
          }}
          handleKeyCommand={handleKeyCommand}
        />
      </div>
    </Rnd>
  );
}

export default FloatingText;
