import { useContext, useEffect, useRef, useState } from "react";
import "./Editor.css";
import { ColumnComponentProps } from "./Component";
import { Rnd } from "react-rnd";
import GenericEditor from "./GenericEditor";
import {
  COLUMN_SEP,
  PAGE_HEIGHT,
  PAGE_SIDE_MARGIN,
  PAGE_TOP_MARGIN,
  PAGE_WIDTH,
} from "./constants/page";
import { ComponentControls } from "./ComponentControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import ApplicationContext, { AppContext } from "./context/ApplicationContext";

function getPossibleLeftValues(numberOfColumns: number) {
  if (numberOfColumns <= 0) {
    return [];
  }
  const COL_WIDTH = calculateWidth(numberOfColumns);
  const out: number[] = [];
  let curr = PAGE_SIDE_MARGIN;
  for (let i = 0; i < numberOfColumns; i++) {
    out.push(curr);
    curr += COL_WIDTH + COLUMN_SEP;
  }
  return out;
}

export function calculateWidth(numberOfColumns: number) {
  return (
    (PAGE_WIDTH - 2 * PAGE_SIDE_MARGIN - COLUMN_SEP * (numberOfColumns - 1)) /
    numberOfColumns
  );
}

function Column(props: ColumnComponentProps) {
  const columnRef = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState<number>(0);
  const [isSelected, setSelected] = useState<boolean>();

  useEffect(() => {
    if (props.position.x !== PAGE_SIDE_MARGIN) {
      const possibleLeftValues = getPossibleLeftValues(props.numberOfColumns);
      let min_diff = Infinity;
      let x = 0;
      for (const leftValue of possibleLeftValues) {
        const diff = Math.abs(leftValue - props.position.x);
        if (diff < min_diff) {
          min_diff = diff;
          x = leftValue;
        }
      }
      props.updateComponentData({
        position: { x, y: props.position.y },
      });
    }
  }, [props.numberOfColumns]);

  const applicationContext = useContext(ApplicationContext) as AppContext;
  useEffect(() => {
    setSelected(applicationContext.selectedComponents.indexOf(props.id) >= 0);
  }, [applicationContext.selectedComponents]);

  return (
    <Rnd
      disableDragging={isSelected}
      enableResizing={
        isSelected
          ? {
              top: true,
              bottom: true,
            }
          : {}
      }
      size={{ width: props.size.width, height: props.size.height }}
      position={props.position}
      onResize={(e, direction, ref, delta, position) => {
        props.updateComponentData({
          position: {
            x: props.position.x,
            y: Math.max(position.y, PAGE_TOP_MARGIN),
          },
          size: {
            width: props.size.width,
            height: Math.min(
              parseInt(ref.style.height),
              PAGE_HEIGHT -
                PAGE_TOP_MARGIN -
                Math.max(position.y, PAGE_TOP_MARGIN)
            ),
          },
        });
      }}
      onDragStop={(e, d) => {
        const possibleLeftValues = getPossibleLeftValues(props.numberOfColumns);
        let min_diff = Infinity;
        let x = 0;
        for (const leftValue of possibleLeftValues) {
          const diff = Math.abs(leftValue - d.x);
          if (diff < min_diff) {
            min_diff = diff;
            x = leftValue;
          }
        }
        props.updateComponentData({
          position: {
            x,
            y: Math.min(
              Math.max(d.y, PAGE_TOP_MARGIN),
              PAGE_HEIGHT - PAGE_TOP_MARGIN - props.size.height
            ),
          },
        });
      }}
      className={`${isSelected ? "bring-to-front" : ""}`}
      style={{ padding: 4 }}
    >
      <div
        className={`component column ${
          isSelected ? "selected-column" : ""
        }  column-background-${props.backgroundImage || "none"}`}
        style={{
          backgroundColor: props.backgroundColor ?? "transparent",
          borderTop: `${props.borderTop?.width || 0}px solid ${
            props.borderTop?.color || "transparent"
          }`,
          borderRight: `${props.borderRight?.width || 0}px solid ${
            props.borderRight?.color || "transparent"
          }`,
          borderBottom: `${props.borderBottom?.width || 0}px solid ${
            props.borderBottom?.color || "transparent"
          }`,
          borderLeft: `${props.borderLeft?.width || 0}px solid ${
            props.borderLeft?.color || "transparent"
          }`,
          boxShadow: `0px 0px ${props.shadowSize || 0}px ${
            props.shadowColor || "transparent"
          }`,
        }}
        ref={columnRef}
        onClick={(e) => {
          props.selectComponent();
          setFocus((focus) => (focus + 1) % 2);
        }}
      >
        {props.duplicate && (
          <div className="component-buttons-holder component-buttons-holder-left">
            <div
              className={`component-button`}
              onClick={props.duplicate}
              title={"Duplicate column on the right"}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </div>
          </div>
        )}
        <ComponentControls
          deleteComponent={props.deleteComponent}
          bringToFront={props.bringToFront}
          sendToBack={props.sendToBack}
        />
        <GenericEditor
          id={props.id}
          focus={focus}
          containers={props.containers}
          contentState={props.contentState}
          updateComponentData={props.updateComponentData}
          promptHandler={props.promptHandler}
          paddingTop={props.paddingTop}
          paddingRight={props.paddingRight}
          paddingBottom={props.paddingBottom}
          paddingLeft={props.paddingLeft}
        />
      </div>
    </Rnd>
  );
}

export default Column;
