import Draft, {
  ContentBlock,
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RawDraftContentState,
  RichUtils,
} from "draft-js";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { Map } from "immutable";
import { ColumnComponentData } from "./Component";
import { getSelectionInlineStyle } from "./utils/draftjs-utils";
const { hasCommandModifier } = KeyBindingUtil;

// custom hook for getting previous value
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function findDiv(id: string, blockId: string): HTMLDivElement | undefined {
  const node = document.getElementById(`editor-container-${id}`);
  if (!node) {
    return;
  }
  const expression = `//div[@data-offset-key='${blockId}-0-0']`;
  const iterator = document.evaluate(
    expression,
    node,
    null,
    XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
    null
  );
  try {
    const div = iterator.iterateNext();
    while (div) {
      return div as HTMLDivElement;
    }
  } catch (e) {
    console.error(`Error: Document tree modified during iteration ${e}`);
  }
  return undefined;
}

export type GenericEditorProps = {
  id: string;
  focus: number;
  containers: string[][];
  contentState?: RawDraftContentState;
  updateComponentData: (newData: Partial<ColumnComponentData>) => void;
  promptHandler?: (prompt: string) => void;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
};

function GenericEditor(props: GenericEditorProps) {
  const [editorState, setEditorState] = useState<EditorState>();
  const [containers, setContainers] = useState<string[][]>([]);
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [highlightedDiv, setHighlightedDiv] = useState<{
    div: HTMLDivElement;
    type: string;
    inlineStyles: { [key: string]: boolean };
  }>();
  const editor = useRef<Editor>(null);
  const prevFocus = usePrevious(props.focus);

  useEffect(() => {
    if (prevFocus !== props.focus) {
      editor.current?.focus();
    }
  }, [props.focus]);

  useEffect(() => {
    if (props.contentState) {
      const contentState = convertFromRaw(props.contentState);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
    setContainers(props.containers);
  }, []);

  useEffect(() => {
    if (!editorState) {
      return;
    }
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const groups: string[][] = [];
    const blocks = blockMap
      .filter((block) => block !== undefined)
      .map((block) => [
        (block as ContentBlock).getKey(),
        (block as ContentBlock).getType(),
      ])
      .toArray();

    let curr = 0;
    let start = 0;
    let end = 0;
    const SPECIAL_CONTAINERS = ["callout", "bordercallout"];
    while (curr < blocks.length) {
      if (SPECIAL_CONTAINERS.includes(blocks[curr][1])) {
        const containerType = blocks[curr][1];
        start = curr;
        while (curr < blocks.length && blocks[curr][1] === containerType) {
          end = curr;
          curr += 1;
          continue;
        }
        groups.push([blocks[start][0], blocks[end][0], containerType]);
      }
      curr += 1;
    }
    setContainers(groups);
  }, [editorState]);

  useEffect(() => {
    if (!editorState) {
      return;
    }
    const contentState = editorState.getCurrentContent();
    const newRawContentState = convertToRaw(contentState);
    props.updateComponentData({ contentState: newRawContentState });
  }, [editorState]);
  useEffect(() => {
    props.updateComponentData({ containers });
  }, [containers]);

  function keyBindingFn(e: KeyboardEvent): string | null {
    if (editorState && props.promptHandler) {
      if (e.key === "Shift") {
        if (shiftPressed) {
          const contentState = editorState.getCurrentContent();
          const selectionState = editorState.getSelection();
          const blockKey = selectionState.getAnchorKey();
          const block = contentState.getBlockForKey(blockKey);
          const text = block.getText();
          props.promptHandler(text);
        } else {
          setShiftPressed(true);
          setTimeout(() => {
            setShiftPressed(false);
          }, 200);
        }
      } else {
        setShiftPressed(false);
      }
    }
    if (e.key === "1" && hasCommandModifier(e)) {
      return "header-one";
    } else if (e.key === "2" && hasCommandModifier(e)) {
      return "header-two";
    } else if (e.key === "3" && hasCommandModifier(e)) {
      return "header-three";
    } else if (e.key === "4" && hasCommandModifier(e)) {
      return "callout";
    } else if (e.key === "5" && hasCommandModifier(e)) {
      return "bordercallout";
    }
    return getDefaultKeyBinding(e);
  }

  function renderContainer(id: string, group: string[], index: number) {
    const [startKey, endKey, containerType] = group;
    const startDiv = findDiv(id, startKey);
    const endDiv = startKey === endKey ? startDiv : findDiv(id, endKey);
    if (!startDiv || !endDiv) {
      return <></>;
    }
    if (containerType === "callout") {
      const rect = endDiv.getBoundingClientRect();
      return (
        <div
          key={`container-${index}`}
          className={`container-${containerType}`}
          style={{
            left: startDiv.offsetLeft - 4,
            top: startDiv.offsetTop - 8,
            width: rect.width + 5,
            height: endDiv.offsetTop + rect.height - startDiv.offsetTop + 20,
          }}
        >
          <div
            className={`container-callout-circle`}
            style={{ right: -4, top: -2 }}
          ></div>
          <div
            className={`container-callout-circle`}
            style={{ left: -4, top: -2 }}
          ></div>
          <div
            className={`container-callout-circle`}
            style={{ left: -4, bottom: -2 }}
          ></div>
          <div
            className={`container-callout-circle`}
            style={{ right: -4, bottom: -2 }}
          ></div>
        </div>
      );
    } else if (containerType === "bordercallout") {
      const rect = endDiv.getBoundingClientRect();
      return (
        <div
          key={`container-${index}`}
          className={`container-${containerType}`}
          style={{
            left: startDiv.offsetLeft - 3,
            top: startDiv.offsetTop - 3,
            width: rect.width + 3,
            height: endDiv.offsetTop + rect.height - startDiv.offsetTop + 6,
          }}
        >
          <div className={`container-bordercallout-bottomleft-triangle`}></div>
          <div className={`container-bordercallout-topleft-triangle`}></div>
          <div className={`container-bordercallout-topright-triangle`}></div>
          <div className={`container-bordercallout-bottomright-triangle`}></div>
        </div>
      );
    }
  }

  function handleKeyCommand(
    command: string,
    editorState: EditorState
  ): DraftHandleValue {
    if (
      command === "header-one" ||
      command === "header-two" ||
      command === "header-three" ||
      command === "callout" ||
      command === "bordercallout"
    ) {
      const selectionState = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const newContentState = Modifier.setBlockType(
        contentState,
        selectionState,
        command
      );
      const newState = EditorState.push(
        editorState,
        newContentState,
        "change-block-type"
      );
      setEditorState(newState);
      return "handled";
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  function blockStyleFn(contentBlock: ContentBlock) {
    if (contentBlock.getText() === "") {
      return `empty-${contentBlock.getType()}`;
    }
    return "";
  }

  function handleReturn(e: React.KeyboardEvent<{}>, editorState: EditorState) {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      return "not-handled";
    }
    const currentContent = editorState.getCurrentContent();
    const shouldClearStyle = !e.shiftKey;
    const textWithEntity = Modifier.splitBlock(currentContent, selection);
    const newEditorState = EditorState.push(
      editorState,
      textWithEntity,
      "split-block"
    );
    if (shouldClearStyle) {
      // unstyle the newly added block
      const newContentState = Modifier.setBlockType(
        newEditorState.getCurrentContent(),
        newEditorState.getSelection(),
        "unstyled"
      );
      const editorStateClearedStyle = EditorState.push(
        newEditorState,
        newContentState,
        "change-block-type"
      );
      setEditorState(editorStateClearedStyle);
    } else {
      setEditorState(newEditorState);
    }

    return "handled";
  }

  const blockRenderMap = Map({
    callout: {
      element: "div",
    },
  });
  const extendedBlockRenderMap =
    Draft.DefaultDraftBlockRenderMap.merge(blockRenderMap);
  return (
    <div
      id={`editor-container-${props.id}`}
      className={
        props.paddingTop ||
        props.paddingRight ||
        props.paddingBottom ||
        props.paddingLeft
          ? `editor-inherit-padding`
          : ``
      }
      style={{
        top: props.paddingTop ?? 2,
        left: props.paddingLeft ?? 2,
        right: props.paddingRight ?? 2,
        bottom: props.paddingBottom ?? 2,
      }}
    >
      {containers.map((container, index) =>
        renderContainer(props.id, container, index)
      )}
      {editorState && (
        <Editor
          ref={editor}
          blockStyleFn={blockStyleFn}
          editorState={editorState}
          onChange={(state) => {
            const selectionState = state.getSelection();
            setEditorState(state);
            if (
              (selectionState.getHasFocus() &&
                selectionState.getAnchorKey() !==
                  selectionState.getFocusKey()) ||
              selectionState.getAnchorOffset() -
                selectionState.getFocusOffset() !==
                0
            ) {
              const blockId = selectionState.getAnchorKey();
              const div = findDiv(props.id, blockId);
              if (div) {
                const contentState = editorState.getCurrentContent();
                const block = contentState.getBlockForKey(blockId);
                const inlineStyles = getSelectionInlineStyle(editorState);
                const type = block.getType();
                setHighlightedDiv({
                  div,
                  type,
                  inlineStyles,
                });
              }
              return;
            }
            setHighlightedDiv(undefined);
          }}
          handleKeyCommand={handleKeyCommand}
          handleReturn={handleReturn}
          keyBindingFn={keyBindingFn}
          blockRenderMap={extendedBlockRenderMap}
        />
      )}
      {highlightedDiv !== undefined && (
        <div
          className=""
          style={{
            position: "absolute",
            left: 4,
            top: highlightedDiv?.div.offsetTop - 36,
            height: 24,
            background: "white",
            display: "flex",
            borderRadius: 4,
            overflow: "hidden",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.5)",
          }}
        >
          <select
            className="select-input"
            onChange={(e) => {
              if (!editorState) {
                return;
              }
              const selectionState = editorState.getSelection();
              const contentState = editorState.getCurrentContent();
              const newContentState = Modifier.setBlockType(
                contentState,
                selectionState,
                e.target.value
              );
              const newState = EditorState.createWithContent(newContentState);
              setEditorState(newState);
              setHighlightedDiv(undefined);
            }}
            onClick={(e) => e.stopPropagation()}
            style={{
              border: "none",
              paddingRight: 8,
              borderRight: "1px solid #ccc",
            }}
            value={highlightedDiv.type}
          >
            <option value={"unstyled"}>Text</option>
            <option value={"header-one"}>Header 1</option>
            <option value={"header-two"}>Header 2</option>
            <option value={"header-three"}>Header 3</option>
            <option value={"callout"}>Border Box</option>
            <option value={"bordercallout"}>Letter Box</option>
          </select>
          <div
            style={{
              fontSize: 14,
              lineHeight: "24px",
              fontWeight: "bold",
              paddingLeft: 8,
              paddingRight: 8,
              cursor: "pointer",
              borderRight: "1px solid #ccc",
              color: highlightedDiv.inlineStyles["BOLD"] ? "#ff8c00" : "black",
            }}
            onClick={(e) => {
              if (!editorState) {
                return;
              }
              const newState = RichUtils.toggleInlineStyle(editorState, "BOLD");
              let hdiv = highlightedDiv;
              hdiv.inlineStyles["BOLD"] = !hdiv.inlineStyles["BOLD"];
              setHighlightedDiv(hdiv);
              setEditorState(newState);
              e.stopPropagation();
            }}
          >
            B
          </div>
          <div
            style={{
              fontSize: 14,
              lineHeight: "24px",
              fontStyle: "italic",
              paddingLeft: 8,
              paddingRight: 8,
              cursor: "pointer",
              borderRight: "1px solid #ccc",
              color: highlightedDiv.inlineStyles["ITALIC"]
                ? "#ff8c00"
                : "black",
            }}
            onClick={(e) => {
              if (!editorState) {
                return;
              }
              const newState = RichUtils.toggleInlineStyle(
                editorState,
                "ITALIC"
              );
              let hdiv = highlightedDiv;
              hdiv.inlineStyles["ITALIC"] = !hdiv.inlineStyles["ITALIC"];
              setHighlightedDiv(hdiv);
              setEditorState(newState);
              e.stopPropagation();
            }}
          >
            I
          </div>
          <div
            style={{
              fontSize: 14,
              lineHeight: "24px",
              textDecoration: "underline",
              paddingLeft: 8,
              paddingRight: 8,
              cursor: "pointer",
              color: highlightedDiv.inlineStyles["UNDERLINE"]
                ? "#ff8c00"
                : "black",
            }}
            onClick={(e) => {
              if (!editorState) {
                return;
              }
              const newState = RichUtils.toggleInlineStyle(
                editorState,
                "UNDERLINE"
              );
              let hdiv = highlightedDiv;
              hdiv.inlineStyles["UNDERLINE"] = !hdiv.inlineStyles["UNDERLINE"];
              setHighlightedDiv(hdiv);
              setEditorState(newState);
              e.stopPropagation();
            }}
          >
            U
          </div>
        </div>
      )}
    </div>
  );
}

export default GenericEditor;
