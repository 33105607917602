import { useState } from "react";
import ColorPicker from "./ColorPicker";
import { PageData } from "./Component";

export type PagePropertiesProps = {
  pageData: Omit<PageData, "id" | "components">;
  index: number;
  updatePageData: (newPageData: Partial<PageData>) => void;
  deletePage: () => void;
};

function PageProperties(props: PagePropertiesProps) {
  const [prompt, setPrompt] = useState<string>();
  return (
    <div className="image-properties">
      <div className="sections-holder">
        <div className="sections-title">Page Properties</div>
        <div className="section">
          <div className="section-title">Background</div>
          <div className="double-input-holder">
            <select
              className="select-input"
              onChange={(e) =>
                props.updatePageData({
                  background: e.target.value as PageData["background"],
                })
              }
              value={props.pageData.background}
            >
              <option value={"default"}>Default</option>
              <option value={"alternate"}>Alternate</option>
              <option value={"beige"}>Beige</option>
              <option value={"blue-alternate"}>Blue</option>
              <option value={"crimson-alternate"}>Crimson</option>
              <option value={"black"}>Black</option>
            </select>
          </div>
        </div>
        <div className="section">
          <div className="section-title">Default Image Background</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.pageData.defaultImageBackground || ""}
              updateColor={(newColor) => {
                props.updatePageData({
                  defaultImageBackground: newColor,
                });
              }}
            />
          </div>
        </div>
        <div className="section section-button-holder">
          <div
            className="component-properties-button component-properties-button-danger"
            onClick={props.deletePage}
          >
            Delete Page
          </div>
        </div>
        <div
          className="section section-button-holder"
          style={{ flexDirection: "column" }}
        >
          <div className="section-title">{prompt}</div>
          <div
            className="component-properties-button"
            onClick={async () => {
              await fetch(
                `/api/sg/${window.location.pathname.split("/")[1]}/${
                  props.index
                }`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setPrompt(data.prompt);
                });
            }}
          >
            {"Suggest Image"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageProperties;
