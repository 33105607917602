import { calculateMaskColor } from "./color";
import ColorPicker from "./ColorPicker";
import { ImageComponentData, Point, Size } from "./Component";

export type ImagePropertiesProps = {
  position: Point;
  size: Size;
  offset: Point;
  zoom: number;
  growth: number;
  iteration: number;
  layerColors: string[];
  opacity: number;
  colorMask?: string;
  updateComponentData: (newData: Partial<ImageComponentData>) => void;
};

function ImageProperties(props: ImagePropertiesProps) {
  return (
    <div className="image-properties">
      <div className="sections-holder">
        <div className="sections-title">Image Properties</div>
        <div className="section">
          <div className="section-title">Canvas Offset</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  position: {
                    x: parseInt(e.target.value),
                    y: props.position.y,
                  },
                })
              }
              value={props.position.x}
            />
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  position: {
                    x: props.position.x,
                    y: parseInt(e.target.value),
                  },
                })
              }
              value={props.position.y}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Image Offset</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  offset: {
                    x: parseInt(e.target.value),
                    y: props.offset.y,
                  },
                })
              }
              value={props.offset.x}
            />
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  offset: {
                    x: props.offset.x,
                    y: parseInt(e.target.value),
                  },
                })
              }
              value={props.offset.y}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Zoom</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({ zoom: parseFloat(e.target.value) })
              }
              value={props.zoom}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Height</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  size: {
                    width: props.size.width,
                    height: parseInt(e.target.value),
                  },
                })
              }
              value={props.size.height}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Width</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  size: {
                    width: parseInt(e.target.value),
                    height: props.size.height,
                  },
                })
              }
              value={props.size.width}
            />
          </div>
        </div>
      </div>

      <div className="sections-holder">
        <div className="sections-title">Image Clipping Properties</div>
        <div className="section">
          <div className="section-title">Layer Count</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  iteration: parseInt(e.target.value),
                })
              }
              value={props.iteration}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Layer Expansion (%)</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  growth: parseFloat(e.target.value) / 100,
                })
              }
              value={props.growth * 100}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Layer Color</div>
          <div className="color-input-holder">
            {props.layerColors.map((color, index) => (
              <ColorPicker
                key={index}
                color={color}
                updateColor={(newColor) =>
                  props.updateComponentData({
                    layerColors: [newColor],
                  })
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className="sections-holder">
        <div className="sections-title">Color Properties</div>
        <div className="section">
          <div className="section-title">Opacity</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  opacity: parseFloat(e.target.value),
                })
              }
              value={props.opacity}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Mask Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.colorMask || ""}
              updateColor={(newColor) => {
                const maskFilter = calculateMaskColor(newColor);
                if (maskFilter) {
                  props.updateComponentData({
                    colorMask: newColor,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageProperties;
