import { useState } from "react";
import { SketchPicker } from "react-color";

function ColorPicker(props: {
  color: string;
  updateColor: (color: string) => void;
}) {
  const [pickingColor, setPickingColor] = useState(false);
  if (pickingColor) {
    return (
      <div className="color-picker-holder">
        <SketchPicker
          color={props.color}
          onChangeComplete={(c) => {
            props.updateColor(c.hex);
          }}
        />
        <div
          className="color-picker-done"
          onClick={(e) => {
            setPickingColor(false);
          }}
        >
          Done
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="layer-color"
        onClick={() => {
          setPickingColor(true);
        }}
        style={{ backgroundColor: props.color }}
      ></div>
    );
  }
}

export default ColorPicker;
