import { useRef, useState, useEffect, useContext } from "react";
import Column, { calculateWidth } from "./Column";
import { PageProps, ComponentData, Point } from "./Component";
import ComponentGallery from "./ComponentGallery";
import ComponentProperties from "./ComponentProperties";
import { COLUMN_SEP, PAGE_SIDE_MARGIN, PAGE_WIDTH } from "./constants/page";
import { v4 as uuidv4 } from "uuid";
import FloatingText from "./FloatingText";
import ImageHolder from "./ImageHolder";
import TableComponent from "./Table";
import ApplicationContext, { AppContext } from "./context/ApplicationContext";

type SelectionBox = {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
};

function Page(props: PageProps) {
  const applicationContext = useContext(ApplicationContext) as AppContext;
  const pageRef = useRef<HTMLDivElement>(null);
  const [components, setComponents] = useState<ComponentData[]>(
    props.components
  );
  useEffect(() => {
    props.updatePageData({ components });
  }, [JSON.stringify(components)]);

  function bringComponentAtIndexToFront(index: number) {
    setComponents((components) => {
      let componentsCopy = JSON.parse(JSON.stringify(components));
      componentsCopy.splice(index, 1);
      let currentComponent = JSON.parse(JSON.stringify(components[index]));
      return [...componentsCopy, currentComponent];
    });
  }

  function sendComponentAtIndexToBack(index: number) {
    setComponents((components) => {
      let componentsCopy = JSON.parse(JSON.stringify(components));
      componentsCopy.splice(index, 1);
      let currentComponent = JSON.parse(JSON.stringify(components[index]));
      return [currentComponent, ...componentsCopy];
    });
  }

  function deleteComponentAtIndex(index: number) {
    setComponents((components) => {
      let componentsCopy = JSON.parse(JSON.stringify(components));
      componentsCopy.splice(index, 1);
      return componentsCopy;
    });
  }

  function selectComponent(componentId: string) {
    if (applicationContext.holdingShift) {
      // add to the components
      if (applicationContext.selectedComponents.indexOf(componentId) >= 0) {
        // already selected, since holding shift, deselect
        const remainingSelectedComponents =
          applicationContext.selectedComponents.filter(
            (c) => c !== componentId
          );
        applicationContext.setSelectedComponents(remainingSelectedComponents);
        return;
      }
      applicationContext.setSelectedComponents([
        ...applicationContext.selectedComponents,
        componentId,
      ]);
    } else {
      applicationContext.setSelectedComponents([componentId]);
    }
  }

  function deselectComponent(
    componentId: string,
    keepAlreadySelected?: boolean
  ) {
    if (keepAlreadySelected) {
      throw new Error(
        `will remove component from the group selection, not implemented yet`
      );
    }
    applicationContext.setSelectedComponents([]);
  }

  function deselectAllComponents() {
    applicationContext.setSelectedComponents([]);
  }

  function getSelectedComponent(): ComponentData | undefined {
    if (applicationContext.selectedComponents.length === 1) {
      const maybeComponentData = components.filter(
        (c) => c.id === applicationContext.selectedComponents[0]
      );
      if (maybeComponentData.length === 1) {
        return maybeComponentData[0];
      }
    }
    return;
  }

  function renderComponent(
    components: ComponentData[],
    index: number
  ): JSX.Element {
    const component = components[index];
    switch (component.type) {
      case "column":
        const nextColumnEmpty =
          component.numberOfColumns === 2 &&
          component.position.x === PAGE_SIDE_MARGIN &&
          components
            .filter((_, i) => index !== i)
            .filter((comp) => comp.type === "column")
            .filter((comp) => comp.position.x > PAGE_SIDE_MARGIN).length === 0;
        const duplicate = nextColumnEmpty
          ? () => {
              const componentData: ComponentData = {
                type: "column",
                id: uuidv4(),
                position: {
                  x: (PAGE_WIDTH + COLUMN_SEP) / 2,
                  y: component.position.y,
                },
                numberOfColumns: 2,
                size: {
                  width: component.size.width,
                  height: component.size.height,
                },
                containers: [],
              };
              setComponents((components) => [...components, componentData]);
              selectComponent(componentData.id);
            }
          : undefined;

        return (
          <Column
            type="column"
            id={component.id}
            key={component.id}
            contentState={component.contentState}
            position={component.position}
            size={{
              width: calculateWidth(component.numberOfColumns),
              height: component.size.height,
            }}
            numberOfColumns={component.numberOfColumns}
            containers={component.containers}
            backgroundColor={component.backgroundColor}
            borderTop={component.borderTop}
            borderRight={component.borderRight}
            borderBottom={component.borderBottom}
            borderLeft={component.borderLeft}
            shadowColor={component.shadowColor}
            shadowSize={component.shadowSize}
            backgroundImage={component.backgroundImage}
            paddingTop={component.paddingTop}
            paddingRight={component.paddingRight}
            paddingBottom={component.paddingBottom}
            paddingLeft={component.paddingLeft}
            pageTopology={components
              .filter((c) => c.id !== component.id)
              .map((c) => {
                return { id: c.id, position: c.position, size: c.size };
              })}
            updateComponentData={(newComponentData) => {
              updateComponentData(component.id, newComponentData);
            }}
            selectComponent={() => {
              selectComponent(component.id);
            }}
            deselectComponent={() => {
              deselectComponent(component.id);
            }}
            bringToFront={() => {
              bringComponentAtIndexToFront(index);
            }}
            sendToBack={() => {
              sendComponentAtIndexToBack(index);
            }}
            deleteComponent={() => {
              deleteComponentAtIndex(index);
            }}
            duplicate={duplicate}
            promptHandler={(prompt) => props.promptHandler(props.id, prompt)}
          />
        );
      case "image":
        return (
          <ImageHolder
            type="image"
            id={component.id}
            key={component.id}
            src={component.src}
            position={component.position}
            size={component.size}
            offset={component.offset}
            zoom={component.zoom}
            iteration={component.iteration}
            growth={component.growth}
            layerColors={component.layerColors}
            clipPoints={component.clipPoints}
            opacity={component.opacity}
            colorMask={component.colorMask}
            pageTopology={components
              .filter((c) => c.id !== component.id)
              .map((c) => {
                return { id: c.id, position: c.position, size: c.size };
              })}
            updateComponentData={(newComponentData) =>
              updateComponentData(component.id, newComponentData)
            }
            selectComponent={() => {
              selectComponent(component.id);
            }}
            deselectComponent={() => {
              deselectComponent(component.id);
            }}
            bringToFront={() => {
              bringComponentAtIndexToFront(index);
            }}
            sendToBack={() => {
              sendComponentAtIndexToBack(index);
            }}
            deleteComponent={() => {
              deleteComponentAtIndex(index);
            }}
          />
        );
      case "floating_text":
        return (
          <FloatingText
            type="floating_text"
            id={component.id}
            key={component.id}
            contentState={component.contentState}
            position={component.position}
            size={component.size}
            color={component.color}
            font={component.font}
            pageTopology={components
              .filter((c) => c.id !== component.id)
              .map((c) => {
                return { id: c.id, position: c.position, size: c.size };
              })}
            updateComponentData={(newComponentData) =>
              updateComponentData(component.id, newComponentData)
            }
            selectComponent={() => {
              selectComponent(component.id);
            }}
            deselectComponent={() => {
              deselectComponent(component.id);
            }}
            bringToFront={() => {
              bringComponentAtIndexToFront(index);
            }}
            sendToBack={() => {
              sendComponentAtIndexToBack(index);
            }}
            deleteComponent={() => {
              deleteComponentAtIndex(index);
            }}
          />
        );
      case "table":
        return (
          <TableComponent
            type="table"
            id={component.id}
            key={component.id}
            position={component.position}
            size={component.size}
            pageTopology={components
              .filter((c) => c.id !== component.id)
              .map((c) => {
                return { id: c.id, position: c.position, size: c.size };
              })}
            updateComponentData={(newComponentData) =>
              updateComponentData(component.id, newComponentData)
            }
            selectComponent={() => {
              selectComponent(component.id);
            }}
            deselectComponent={() => {
              deselectComponent(component.id);
            }}
            bringToFront={() => {
              bringComponentAtIndexToFront(index);
            }}
            sendToBack={() => {
              sendComponentAtIndexToBack(index);
            }}
            deleteComponent={() => {
              deleteComponentAtIndex(index);
            }}
          />
        );
      default:
        assertNever(component);
        throw Error(
          `Component does not have a renderer: ${JSON.stringify(component)}`
        );
    }
  }

  function updateComponentData<T extends ComponentData>(
    componentId: string,
    newComponentData: Partial<T>
  ) {
    props.saveProgress();
    setComponents(
      components.map((component) => {
        if (component.id === componentId) {
          return {
            ...component,
            ...newComponentData,
          };
        } else {
          return component;
        }
      })
    );
  }

  function assertNever(shouldBeNever: never) {
    throw new Error("Was not never: " + shouldBeNever);
  }

  useEffect(() => {
    props.saveProgress();
    setComponents(
      components.map((component) => {
        if (
          applicationContext.groupDrag &&
          applicationContext.selectedComponents.indexOf(component.id) >= 0 &&
          (component.type === "floating_text" || component.type === "image")
        ) {
          return {
            ...component,
            ...{
              position: {
                x: component.position.x + applicationContext.groupDrag.deltaX,
                y: component.position.y + applicationContext.groupDrag.deltaY,
              },
            },
          };
        } else {
          return component;
        }
      })
    );
  }, [applicationContext.groupDrag]);

  const [startPosition, setStartPosition] = useState<Point>();
  const [currentPosition, setCurrentPosition] = useState<Point>();
  const [isDragging, setIsDragging] = useState<boolean>();
  function handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    const overlappingComponents = getOverlappingComponents(e);
    if (overlappingComponents.length > 0) {
      return;
    }
    setIsDragging(false);
    setStartPosition({ x: e.clientX, y: e.clientY });
    setCurrentPosition({ x: e.clientX, y: e.clientY });
  }

  function handleMouseMove(e: React.MouseEvent<HTMLDivElement>) {
    if (startPosition) {
      setIsDragging(true);
      setCurrentPosition({ x: e.clientX, y: e.clientY });
      updateSelectedComponents();
    }
  }

  function handleMouseUp(e: React.MouseEvent<HTMLDivElement>) {
    setStartPosition(undefined);
    setCurrentPosition(undefined);
  }

  const isIntersecting = (
    childRect: SelectionBox,
    selectionBox: SelectionBox
  ) => {
    return !(
      childRect.left > selectionBox.right ||
      childRect.right < selectionBox.left ||
      childRect.top > selectionBox.bottom ||
      childRect.bottom < selectionBox.top
    );
  };

  function computeSelectionBox(): SelectionBox | null {
    if (!startPosition || !currentPosition || !pageRef.current) return null;

    // Calculate the relative positions
    const pageRect = pageRef.current.getBoundingClientRect();
    const startX = startPosition.x - pageRect.left;
    const startY = startPosition.y - pageRect.top;
    const currentX = currentPosition.x - pageRect.left;
    const currentY = currentPosition.y - pageRect.top;

    // Determine top-left and bottom-right corners of the selection box
    const x = Math.min(startX, currentX);
    const y = Math.min(startY, currentY);
    const width = Math.abs(startX - currentX);
    const height = Math.abs(startY - currentY);

    return {
      left: x,
      top: y,
      right: x + width,
      bottom: y + height,
      width,
      height,
    };
  }

  function updateSelectedComponents() {
    const selectionBox = computeSelectionBox();
    if (!selectionBox) return;
    const componentIds = components
      .map((c) => {
        return {
          left: c.position.x,
          top: c.position.y,
          right: c.position.x + c.size.width,
          bottom: c.position.y + c.size.height,
          width: c.size.width,
          height: c.size.height,
          id: c.id,
        } as SelectionBox & { id: string };
      })
      .filter((selectionBoxWithId) =>
        isIntersecting(selectionBoxWithId, selectionBox)
      )
      .map((selectionBoxWithId) => selectionBoxWithId.id);
    applicationContext.setSelectedComponents(componentIds);
  }

  function getOverlappingComponents(e: React.MouseEvent<HTMLDivElement>) {
    return components
      .map((c) => {
        return {
          left: c.position.x,
          top: c.position.y,
          right: c.position.x + c.size.width,
          bottom: c.position.y + c.size.height,
          width: c.size.width,
          height: c.size.height,
          id: c.id,
        } as SelectionBox & { id: string };
      })
      .filter((selectionBoxWithId) => {
        if (!pageRef.current) {
          return false;
        }
        const parentRect = pageRef.current.getBoundingClientRect();
        const clickBox = {
          left: e.clientX - parentRect.left,
          top: e.clientY - parentRect.top,
          right: e.clientX - parentRect.left + 1,
          bottom: e.clientY - parentRect.top + 1,
          width: 1,
          height: 1,
        };
        return isIntersecting(selectionBoxWithId, clickBox);
      });
  }

  return (
    <div
      className="single-page-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ComponentGallery
        defaultImageBackground={props.defaultImageBackground}
        currentComponents={components}
        addComponent={(componentData) => {
          setComponents((components) => [...components, componentData]);
          selectComponent(componentData.id);
        }}
      />
      <div
        id={props.id}
        className={`page page-${props.background}`}
        ref={pageRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={(e) => {
          if (isDragging) {
            e.stopPropagation();
            setIsDragging(false);
          } else {
            const overlappingComponents = getOverlappingComponents(e);
            if (overlappingComponents.length === 0) {
              deselectAllComponents();
            }
          }
        }}
      >
        {components.map((_, index) => renderComponent(components, index))}
        {computeSelectionBox() && (
          <div
            className="page-selection-box"
            style={{
              left: computeSelectionBox()?.left,
              top: computeSelectionBox()?.top,
              width: computeSelectionBox()?.width,
              height: computeSelectionBox()?.height,
            }}
          />
        )}
      </div>
      <ComponentProperties
        index={props.index}
        selectedComponent={getSelectedComponent()}
        allSelectedComponents={components.filter(
          (c) => applicationContext.selectedComponents.indexOf(c.id) >= 0
        )}
        updateComponentData={updateComponentData}
        pageData={{
          background: props.background,
          defaultImageBackground: props.defaultImageBackground,
        }}
        deleteComponents={() => {
          const newComponents = components.filter(
            (c) => applicationContext.selectedComponents.indexOf(c.id) === -1
          );
          setComponents(newComponents);
        }}
        sendComponentsToBack={() => {
          const selectedComponents = components.filter(
            (c) => applicationContext.selectedComponents.indexOf(c.id) >= 0
          );
          const unselectedComponents = components.filter(
            (c) => applicationContext.selectedComponents.indexOf(c.id) === -1
          );
          setComponents([...selectedComponents, ...unselectedComponents]);
        }}
        bringComponentsToFront={() => {
          const selectedComponents = components.filter(
            (c) => applicationContext.selectedComponents.indexOf(c.id) >= 0
          );
          const unselectedComponents = components.filter(
            (c) => applicationContext.selectedComponents.indexOf(c.id) === -1
          );
          setComponents([...unselectedComponents, ...selectedComponents]);
        }}
        updatePageData={props.updatePageData}
        deletePage={props.deletePage}
      />
    </div>
  );
}

export default Page;
