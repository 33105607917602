import { useRef, useState } from "react";
import ColorPicker from "./ColorPicker";
import {
  Border,
  ColumnBackground,
  ComponentData,
  Point,
  Size,
} from "./Component";
import { v4 as uuidv4 } from "uuid";

export type ColumnPropertiesProps = {
  position: Point;
  size: Size;
  numberOfColumns: number;
  updateComponentData: (newData: Partial<ComponentData>) => void;
  backgroundColor?: string;
  borderTop?: Border;
  borderRight?: Border;
  borderBottom?: Border;
  borderLeft?: Border;
  shadowColor?: string;
  shadowSize?: number;
  backgroundImage?: ColumnBackground;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
};

function ColumnProperties(props: ColumnPropertiesProps) {
  const templateNameRef = useRef<HTMLInputElement>(null);
  const [saved, setSaved] = useState<boolean>();
  return (
    <div className="image-properties">
      <div className="sections-holder">
        <div className="sections-title">Column Properties</div>
        <div className="section">
          <div className="section-title">Number of Columns in Row</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) => {
                const numberOfColumns = parseInt(e.target.value);
                if (isNaN(numberOfColumns) || numberOfColumns === undefined) {
                  return;
                }
                props.updateComponentData({
                  numberOfColumns: Math.min(Math.max(numberOfColumns, 1), 3), // clamp to 1, 2 or 3
                });
              }}
              value={props.numberOfColumns.toString()}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Column Top Offset</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  position: {
                    x: props.position.x,
                    y: parseInt(e.target.value),
                  },
                })
              }
              value={props.position.y}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Column Height</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  size: {
                    width: props.size.width,
                    height: parseInt(e.target.value),
                  },
                })
              }
              value={props.size.height}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Column Width</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              value={props.size.width}
              disabled={true}
            />
          </div>
        </div>
        <div className="section" />
        <div className="sections-title">Background</div>
        <div className="section">
          <div className="section-title">Column Background</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.backgroundColor || ""}
              updateColor={(newColor) => {
                props.updateComponentData({
                  backgroundColor: newColor,
                });
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Background Image</div>
          <div className="double-input-holder">
            <select
              className="select-input"
              onChange={(e) =>
                props.updateComponentData({
                  backgroundImage: e.target.value as ColumnBackground,
                })
              }
              value={props.backgroundImage}
            >
              <option value={"none"}>None</option>
              <option value={"default"}>Default</option>
              <option value={"alternate"}>Alternate</option>
              <option value={"beige"}>Beige</option>
              <option value={"blue-alternate"}>Blue</option>
              <option value={"crimson-alternate"}>Crimson</option>
              <option value={"black"}>Black</option>
            </select>
          </div>
        </div>
        <div className="section">
          <div className="section-title">Shadow Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.shadowColor || ""}
              updateColor={(newColor) => {
                props.updateComponentData({
                  shadowColor: newColor,
                });
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Shadow Size</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  shadowSize: parseInt(e.target.value),
                })
              }
              value={props.shadowSize || 0}
            />
          </div>
        </div>
        <div className="section"></div>
        <div className="sections-title">Padding</div>
        <div className="section">
          <div className="section-title">Padding Top</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  paddingTop: parseInt(e.target.value),
                })
              }
              value={props.paddingTop || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Padding Right</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  paddingRight: parseInt(e.target.value),
                })
              }
              value={props.paddingRight || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Padding Bottom</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  paddingBottom: parseInt(e.target.value),
                })
              }
              value={props.paddingBottom || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Padding Left</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  paddingLeft: parseInt(e.target.value),
                })
              }
              value={props.paddingLeft || 0}
            />
          </div>
        </div>
        <div className="section" />
        <div className="sections-title">Borders</div>
        <div className="section">
          <div className="section-title">Border Top Width</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  borderTop: {
                    width: parseInt(e.target.value),
                    color: props.borderTop?.color || "",
                  },
                })
              }
              value={props.borderTop?.width || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Top Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.borderTop?.color || ""}
              updateColor={(newColor) => {
                props.updateComponentData({
                  borderTop: {
                    width: props.borderTop?.width || 0,
                    color: newColor,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Right Width</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  borderRight: {
                    width: parseInt(e.target.value),
                    color: props.borderRight?.color || "",
                  },
                })
              }
              value={props.borderRight?.width || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Right Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.borderRight?.color || ""}
              updateColor={(newColor) => {
                props.updateComponentData({
                  borderRight: {
                    width: props.borderRight?.width || 0,
                    color: newColor,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Bottom Width</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  borderBottom: {
                    width: parseInt(e.target.value),
                    color: props.borderBottom?.color || "",
                  },
                })
              }
              value={props.borderBottom?.width || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Bottom Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.borderBottom?.color || ""}
              updateColor={(newColor) => {
                props.updateComponentData({
                  borderBottom: {
                    width: props.borderBottom?.width || 0,
                    color: newColor,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Left Width</div>
          <div className="color-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  borderLeft: {
                    width: parseInt(e.target.value),
                    color: props.borderLeft?.color || "",
                  },
                })
              }
              value={props.borderLeft?.width || 0}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Border Left Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.borderLeft?.color || ""}
              updateColor={(newColor) => {
                props.updateComponentData({
                  borderLeft: {
                    width: props.borderLeft?.width || 0,
                    color: newColor,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="section" />
        <div className="sections-title">Template</div>
        {!saved && (
          <div className="section section-button-holder">
            <input
              ref={templateNameRef}
              className="template-name-input"
              placeholder="Template Name"
            />
            <div
              className="component-properties-button"
              onClick={async () => {
                if (!templateNameRef.current) {
                  return;
                }
                const name = templateNameRef.current.value;
                if (!name) {
                  return;
                }
                await fetch(`/api/template/column/${uuidv4()}`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  body: JSON.stringify({
                    template: {
                      name,
                      backgroundColor: props.backgroundColor,
                      borderTop: props.borderTop,
                      borderRight: props.borderRight,
                      borderBottom: props.borderBottom,
                      borderLeft: props.borderLeft,
                      shadowColor: props.shadowColor,
                      shadowSize: props.shadowSize,
                      backgroundImage: props.backgroundImage,
                      paddingTop: props.paddingTop,
                      paddingRight: props.paddingRight,
                      paddingBottom: props.paddingBottom,
                      paddingLeft: props.paddingLeft,
                    },
                  }),
                }).catch((e) => {
                  return;
                });
                setSaved(true);
              }}
            >
              Save
            </div>
          </div>
        )}
        {saved && (
          <div className="section">
            <div className="section-title">Saved</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ColumnProperties;
