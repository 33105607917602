import React from "react";
import { DraggableData } from "react-rnd";

export type AppContext = {
  selectedComponents: string[];
  setSelectedComponents: React.Dispatch<React.SetStateAction<string[]>>;
  holdingShift: boolean;
  groupDrag?: DraggableData;
  setGroupDrag: React.Dispatch<React.SetStateAction<DraggableData | undefined>>;
};

const ApplicationContext = React.createContext<AppContext | undefined>(
  undefined
);

export default ApplicationContext;
