import ColorPicker from "./ColorPicker";
import { FloatingTextComponentData, Point, Size } from "./Component";

export type FloatingTextPropertiesProps = {
  position: Point;
  size: Size;
  color: string;
  updateComponentData: (newData: Partial<FloatingTextComponentData>) => void;
};

function FloatingTextProperties(props: FloatingTextPropertiesProps) {
  return (
    <div className="image-properties">
      <div className="sections-holder">
        <div className="sections-title">Text Properties</div>
        <div className="section">
          <div className="section-title">Position</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  position: {
                    x: parseInt(e.target.value),
                    y: props.position.y,
                  },
                })
              }
              value={props.position.x}
            />
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  position: {
                    x: props.position.x,
                    y: parseInt(e.target.value),
                  },
                })
              }
              value={props.position.y}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Size</div>
          <div className="double-input-holder">
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  size: {
                    width: parseInt(e.target.value),
                    height: props.size.height,
                  },
                })
              }
              value={props.size.width}
            />
            <input
              className="small-number-input"
              type={"number"}
              onChange={(e) =>
                props.updateComponentData({
                  size: {
                    width: props.size.width,
                    height: parseInt(e.target.value),
                  },
                })
              }
              value={props.size.height}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title">Font Color</div>
          <div className="color-input-holder">
            <ColorPicker
              color={props.color}
              updateColor={(color) => {
                props.updateComponentData({ color });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloatingTextProperties;
