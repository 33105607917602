import "./App.css";
import { useEffect, useRef, useState } from "react";
import { FileMetadata, PageData } from "./Component";
import { v4 as uuidv4 } from "uuid";
import Page from "./Page";
import { debounce } from "lodash";
import SideBar from "./SideBar";
import ChatComponent from "./ChatComponent";
import { useKey } from "react-use";
import ApplicationContext from "./context/ApplicationContext";
import { DraggableData } from "react-rnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPlus } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";

function saveProgress(
  pages: PageData[],
  metadata?: FileMetadata,
  title?: string,
  callback?: () => void
) {
  if (!pages) {
    return;
  }
  const fileId = window.location.pathname.split("/")[1];
  const fileData = JSON.stringify(pages);

  fetch(`/api/f/${fileId}`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fileData }),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (callback) {
        callback();
      }
    });

  if (pages && pages.length > 0 && metadata) {
    const firstPageId = pages[0].id;
    const div = document.getElementById(firstPageId);
    if (div) {
      html2canvas(div, {
        scale: 2,
        allowTaint: false,
        backgroundColor: null,
        logging: false,
      }).then((canvas) => {
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        if (!tempCtx) return;
        const thumbnailSize = 640.0;
        tempCanvas.width = thumbnailSize;
        tempCanvas.height = thumbnailSize;
        const width = canvas.width;
        tempCtx.drawImage(
          canvas,
          0,
          0,
          width,
          width,
          0,
          0,
          thumbnailSize,
          thumbnailSize
        );
        const image = tempCanvas.toDataURL("image/jpeg", 1.0);
        const newMetadata = { ...metadata, image };
        if (title) {
          newMetadata.title = title;
        }
        fetch(`/api/fm/${fileId}`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ metadata: newMetadata }),
        });
      });
    }
  }
}

async function getProgress(): Promise<PageData[]> {
  const fileId = window.location.pathname.split("/")[1];

  return fetch(`/api/f/${fileId}`, {
    method: "get",
    headers: {
      Accept: "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data.data as PageData[];
    })
    .catch(function (err) {
      return [];
    });
}

const debouncedSave = debounce(
  (
    pages: PageData[],
    metadata?: FileMetadata,
    title?: string,
    callback?: () => void
  ) => {
    saveProgress(pages, metadata, title, callback);
  },
  4000
);

function App() {
  const fileId = window.location.pathname.split("/")[1];
  const [selectedComponents, setSelectedComponents] = useState<string[]>([]);
  const [groupDrag, setGroupDrag] = useState<DraggableData>();
  const appContainerRef = useRef<HTMLDivElement>(null);
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const [pages, setPages] = useState<PageData[]>([]);
  const [availableFiles, setAvailableFiles] = useState<FileMetadata[]>([]);
  const [saved, setSaved] = useState<boolean>(false);
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<FileMetadata>();

  const [prompt, setPrompt] = useState<{
    chatId: string;
  }>();

  useKey(
    (e) => true,
    (e) => {
      if (e.key === "Control") {
        if (shiftPressed) {
          setPrompt({ chatId: window.location.pathname.split("/")[1] });
        } else {
          setShiftPressed(true);
          setTimeout(() => {
            setShiftPressed(false);
          }, 200);
        }
      } else {
        setShiftPressed(false);
      }
      if (e.key.toLowerCase() === "s" && e.metaKey) {
        saveProgress(pages, metadata, undefined, shimmerSave);
        e.preventDefault();
      }
      if (e.key === "Escape") {
        setSelectedComponents([]);
      }
    }
  );

  const shimmerSave = function () {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 250);
  };

  const [holdingShift, setHoldingShift] = useState<boolean>(false);
  const holdingShiftRef = useRef(holdingShift); // Create a ref

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProgress();
      if (data.length > 0) {
        setPages(data);
      } else {
        setPages([
          {
            id: uuidv4(),
            components: [],
            background: "default",
            defaultImageBackground: "#A8907D",
          },
        ]);
      }
    };
    const fetchAvailableFiles = async () => {
      const data = await fetch(`/api/files`, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data.data as FileMetadata[];
        })
        .catch(function (err) {
          return [];
        });
      setAvailableFiles(data);
    };
    const fetchMetadata = async () => {
      const fileId = window.location.pathname.split("/")[1];
      const data = await fetch(`/api/fm/${fileId}`, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data.metadata as FileMetadata;
        })
        .catch(function (err) {
          return {} as FileMetadata;
        });
      setMetadata(data);
    };

    const fileId = window.location.pathname.split("/")[1];
    if (fileId !== "") {
      fetchData();
      fetchMetadata();
    } else {
      fetchAvailableFiles();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey) {
        setHoldingShift(true);
      }
    };

    const handleKeyUp = () => {
      setHoldingShift(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  useEffect(() => {
    holdingShiftRef.current = holdingShift; // Update the ref value whenever the state changes
  }, [holdingShift]);

  return (
    <ApplicationContext.Provider
      value={{
        selectedComponents,
        setSelectedComponents,
        holdingShift,
        groupDrag,
        setGroupDrag,
      }}
    >
      <div
        className="app-container"
        ref={appContainerRef}
        onClick={() => {
          setSelectedComponents([]);
        }}
      >
        {fileId !== "" && (
          <SideBar
            pageIds={pages.map((pageData) => pageData.id)}
            metadata={metadata}
            saved={saved}
            saveProgress={(title) =>
              debouncedSave(pages, metadata, title, shimmerSave)
            }
          />
        )}
        {prompt && (
          <ChatComponent
            id={prompt.chatId}
            dismiss={() => {
              setPrompt(undefined);
            }}
          />
        )}

        {fileId !== "" && (
          <div className="page-container" ref={pageContainerRef}>
            {pages.map((pageData, index) => (
              <div key={index}>
                <Page
                  key={pageData.id}
                  id={pageData.id}
                  index={index}
                  components={pageData.components}
                  background={pageData.background}
                  defaultImageBackground={pageData.defaultImageBackground}
                  promptHandler={() => {}}
                  deletePage={() => {
                    if (pages.length === 1) {
                      // only a single page, don't allow deletion
                      return;
                    }
                    setPages((pages) =>
                      pages.filter((page) => page.id !== pageData.id)
                    );
                  }}
                  saveProgress={() =>
                    debouncedSave(pages, metadata, undefined, shimmerSave)
                  }
                  updatePageData={(newPageData: Partial<PageData>) => {
                    setPages((pages) =>
                      pages.map((p, i) => {
                        if (i === index) {
                          return {
                            ...p,
                            ...newPageData,
                            id: p.id,
                          };
                        } else {
                          return p;
                        }
                      })
                    );
                  }}
                />
                <div
                  className="insert-page-panel"
                  onClick={() => {
                    setPages((pages) => {
                      const beforePages = pages.slice(0, index + 1);
                      const afterPages = pages.slice(index + 1, pages.length);
                      return [
                        ...beforePages,
                        {
                          id: uuidv4(),
                          components: [],
                          columns: 2,
                          background: "default",
                          defaultImageBackground:
                            beforePages.length > 0
                              ? beforePages[beforePages.length - 1]
                                  .defaultImageBackground
                              : afterPages.length > 0
                              ? afterPages[0].defaultImageBackground
                              : "#A8907D",
                        },
                        ...afterPages,
                      ];
                    });
                  }}
                >
                  {index === pages.length - 1 ? `Add Page` : `Insert Page`}
                </div>
              </div>
            ))}
          </div>
        )}
        {fileId === "" && (
          <div className="file-list">
            <div
              key={`new-file`}
              className="file-item"
              style={{ position: "relative" }}
              onClick={() => {
                window.location.href = `/${uuidv4()}`;
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faPlus} size={"2xl"}></FontAwesomeIcon>
              </div>
              <div className="file-item-title">Create New File</div>
            </div>
            {availableFiles.map((file) => (
              <div
                key={file.id}
                className="file-item"
                style={{
                  backgroundImage: file.image
                    ? `url(${file.image})`
                    : undefined,
                  backgroundSize: "cover",
                  backgroundPosition: "top center",
                }}
                onClick={() => {
                  window.location.href = `/${file.id}`;
                }}
              >
                <div className="file-item-title">
                  <div>{file.title ?? "Untitled"}</div>
                  <div className="file-item-actions">
                    <FontAwesomeIcon icon={faEllipsis} size={"sm"} />
                    <div
                      className="file-item-action"
                      onClick={(e) => {
                        e.stopPropagation();
                        fetch(`/api/f/${file.id}/archive`, {
                          method: "post",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            setAvailableFiles((files) =>
                              files.filter((f) => f.id !== file.id)
                            );
                          });
                      }}
                    >
                      Archive
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </ApplicationContext.Provider>
  );
}

export default App;
