import {
  faCheckCircle,
  faSave,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FileMetadata } from "./Component";

export type SideBarProps = {
  saved: boolean;
  pageIds: string[];
  metadata?: FileMetadata;
  saveProgress: (title?: string) => void;
};

function SideBar(props: SideBarProps) {
  const { metadata } = props;
  return (
    <div className="side-bar">
      <div className="side-bar-internal">
        <div className="side-bar-file-name">
          <input
            className="side-bar-file-name-input"
            placeholder="Untitled"
            defaultValue={metadata && metadata.title ? metadata.title : ""}
            onKeyUp={async (e) => {
              props.saveProgress(e.currentTarget.value);
            }}
          ></input>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={`sidebar-control-button-holder ${
              props.saved ? `shimmer-button` : ``
            }`}
            onClick={() => props.saveProgress()}
          >
            <div className="sidebar-control-button">
              <FontAwesomeIcon
                icon={props.saved ? faCheckCircle : faSave}
                size={"xl"}
              />
            </div>
            <div className="sidebar-control-button-label">Save</div>
          </div>
          <div
            className="sidebar-control-button-holder"
            onClick={async () => {
              let doc = new jsPDF("p", "px", "a4");
              for (let i = 0; i < props.pageIds.length; i++) {
                const pageId = props.pageIds[i];
                const div = document.getElementById(pageId);
                if (div) {
                  await html2canvas(div, {
                    scale: 2,
                    allowTaint: false,
                    backgroundColor: null,
                    logging: false,
                  }).then((canvas) => {
                    var base64image = canvas.toDataURL("image/jpeg", 1.0);
                    var width = doc.internal.pageSize.getWidth();
                    var height = doc.internal.pageSize.getHeight();
                    if (i > 0) {
                      doc.addPage();
                    }
                    doc.addImage(base64image, "JPEG", 0, 0, width, height);
                  });
                }
              }
              await doc.save("test.pdf");
            }}
          >
            <div className="sidebar-control-button">
              <FontAwesomeIcon icon={faFilePdf} size={"xl"} />
            </div>
            <div className="sidebar-control-button-label">PDF Export</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
